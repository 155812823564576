import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1fced9ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "u-fontSize-vue--medium" }
const _hoisted_3 = { class: "u-marginBottomUI--small" }
const _hoisted_4 = {
  key: 0,
  class: "c-siteReview-card u-marginBottomUI--xxSmall"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "u-marginBottomUI--small u-fontWeight--bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_star_rating = _resolveComponent("star-rating")!
  const _component_okendo_site_review = _resolveComponent("okendo-site-review")!
  const _directive_prevent_click_in_preview = _resolveDirective("prevent-click-in-preview")!

  return (_ctx.isSiteReviewVisible)
    ? (_openBlock(), _createElementBlock("form", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('Review {name}', { name: _ctx.storeName })), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('How would you rate your overall experience with us?')), 1),
        (_ctx.isCoverCardVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_ctx.avatarImageUrl)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.avatarImageUrl,
                    class: "c-siteReview-card--image u-marginBottomUI--small"
                  }, null, 8, _hoisted_5))
                : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('What did you think about {productName}?', { productName: _ctx.storeName })), 1)),
              _withDirectives(_createVNode(_component_star_rating, {
                modelValue: _ctx.siteRating,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.siteRating) = $event))
              }, null, 8, ["modelValue"]), [
                [_directive_prevent_click_in_preview]
              ])
            ]))
          : _createCommentVNode("", true),
        (!_ctx.isCoverCardVisible)
          ? (_openBlock(), _createBlock(_component_okendo_site_review, { key: 1 }))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}