import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "c-postReviewReferralModule"
}
const _hoisted_2 = {
  "data-oke-referrals-widget": "",
  class: "okeReferrals"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_view_state = _resolveComponent("view-state")!

  return (_ctx.isRatingAboveThreshold)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_view_state, { "view-state": "loading" })
        ])
      ]))
    : _createCommentVNode("", true)
}