
import { StyleSettings } from '@okendo/reviews-common';
import { Options, Vue } from 'vue-class-component';

import store from '@/store';
import ViewState from '@/shared-components/ViewState.vue';
import { loadReferralsWidgetScript } from '@/utils/referralUtil';

@Options({
    components: {
        ViewState
    },
    store
})
export default class Referral extends Vue {
    mounted(): void {
        this.loadScript();
    }

    loadScript(): void {
        loadReferralsWidgetScript();
    }

    get referralModule(): StyleSettings.RecorderPlus.Module.Referral | undefined {
        const { postReviewModules } = store.state.settings.settings;
        const referralModule = postReviewModules?.find(module => module.type === 'referral');
        return referralModule as StyleSettings.RecorderPlus.Module.Referral | undefined;
    }

    get isRatingAboveThreshold(): boolean {
        if (store.state.subscriber.previewMode) {
            return true;
        }

        const { minimumRating } = this.referralModule ?? {};
        const { rating } = store.state.review.review ?? {};

        return !!rating && !!minimumRating && rating >= minimumRating;
    }
}
