import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a3fd584e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "c-productsToReview-heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_to_review = _resolveComponent("product-to-review")!

  return (_ctx.remainingProductsToReview.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('Review More Products')), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.remainingProductsToReview, (product) => {
          return (_openBlock(), _createBlock(_component_product_to_review, {
            key: product.productId,
            product: product
          }, null, 8, ["product"]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}