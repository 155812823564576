
import { Options, Vue } from 'vue-class-component';

import StarRating from '@/shared-components/StarRating.vue';
import OkendoSiteReview from './OkendoSiteReview.vue';
import store from '@/store';
import { StoreMethod } from '@/store/storeTypings';
import { postAnalytics } from '@/utils/api';

@Options({
    components: {
        StarRating,
        OkendoSiteReview
    },
    store
})
export default class SiteReview extends Vue {
    rating = '0';
    isCoverCardVisible = true;

    get storeName(): string | undefined {
        return store.state.subscriber.storeName;
    }

    get isPreviewMode(): boolean {
        return store.state.subscriber.previewMode;
    }

    get isSiteReviewVisible(): boolean {
        const { hasSiteReview } = store.state.siteReview;
        const { name } = store.state.profile.reviewerProfile;
        const { reviewRequestId } = store.state.order;
        return !hasSiteReview && !!name && !!reviewRequestId;
    }

    get siteRating(): string {
        return this.rating;
    }

    set siteRating(siteRating: string) {
        this.rating = siteRating;
        store.commit<StoreMethod>({
            type: 'siteReview/UPDATE_SITE_RATING',
            siteRating
        });

        this.isCoverCardVisible = false;

        postAnalytics({
            eventName: 'change-verified-reviews-rating',
            label: 'siteRating',
            value: siteRating
        });
    }

    get avatarImageUrl(): string | undefined {
        return store.state.subscriber.avatarImageUrl;
    }
}
