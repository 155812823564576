import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "c-survey c-survey--recorderStyling"
}
const _hoisted_2 = { class: "c-survey-heading" }
const _hoisted_3 = { class: "c-survey-heading-title" }
const _hoisted_4 = {
  key: 0,
  class: "c-survey-heading-subtitle"
}
const _hoisted_5 = {
  "data-oke-survey-capture": "",
  class: "okeSurveys"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_view_state = _resolveComponent("view-state")!

  return (_ctx.isModuleVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.headingText), 1),
          (_ctx.subheadingText)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.subheadingText), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_view_state, { "view-state": "loading" })
        ])
      ]))
    : _createCommentVNode("", true)
}