import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1be0a8b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "c-emailVerification"
}
const _hoisted_2 = { class: "c-emailVerification-heading" }
const _hoisted_3 = { class: "c-emailVerification-subheading" }
const _hoisted_4 = { class: "c-main" }
const _hoisted_5 = { class: "c-form-col u-marginLeftUI--small" }
const _hoisted_6 = { class: "u-fontWeight--bold u-marginBottomUI--xxxSmall u-fontSize-vue--medium" }
const _hoisted_7 = { class: "c-previouslyReviewed-subtitle" }
const _hoisted_8 = {
  key: 2,
  class: "u-marginVerticalUI--small"
}
const _hoisted_9 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_achievement_header = _resolveComponent("achievement-header")!
  const _component_product_image = _resolveComponent("product-image")!
  const _component_powered_by_okendo = _resolveComponent("powered-by-okendo")!
  const _component_view_state = _resolveComponent("view-state")!
  const _directive_prevent_click_in_preview = _resolveDirective("prevent-click-in-preview")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isPreviouslyReviewed && _ctx.viewState === 'content')
      ? (_openBlock(), _createBlock(_component_achievement_header, {
          key: 0,
          class: "u-marginTopUI--large"
        }))
      : _createCommentVNode("", true),
    (_ctx.isAnonymousReview)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('Please Verify Your Email Address')), 1),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('You will receive an email with a verification link soon')), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.viewState === 'content')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.isPreviouslyReviewed)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass([{
                    'c-previouslyReviewed--no-border': _ctx.hasLoyaltyModules
                }, "c-previouslyReviewed c-form-row u-marginBottomUI--small"])
                }, [
                  _createVNode(_component_product_image, {
                    imageUrl: _ctx.product.imageUrl,
                    imageWidth: 80,
                    imageHeight: 80,
                    class: "c-form-col"
                  }, null, 8, ["imageUrl"]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.product.name), 1),
                    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('You\'ve already left a review for this product')), 1)
                  ])
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.hasLoyaltyModules && _ctx.loyaltyProfileComponent)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.loyaltyProfileComponent), { key: 1 }))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.moduleComponents, (component) => {
              return (_openBlock(), _createBlock(_resolveDynamicComponent(component.component || component.type), {
                moduleData: component,
                key: component.type,
                class: "c-module"
              }, null, 8, ["moduleData"]))
            }), 128)),
            (_ctx.showGotoStoreButton)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _withDirectives(_createElementVNode("button", {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.gotoStore())),
                    "aria-label": _ctx.$t('Return to {storeName}', { storeName: _ctx.storeName }),
                    class: "c-button"
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('Return to {storeName}', { storeName: _ctx.storeName })), 1)
                  ], 8, _hoisted_9), [
                    [_directive_prevent_click_in_preview]
                  ])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_powered_by_okendo)
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(_component_view_state, {
        "view-state": _ctx.viewState,
        onRetry: _ctx.loadPostReviewData
      }, null, 8, ["view-state", "onRetry"])
    ])
  ], 64))
}