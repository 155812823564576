
import { ProductRecorderView, StyleSettings } from '@okendo/reviews-common';
import { Options, Vue } from 'vue-class-component';
import { Component, defineAsyncComponent } from 'vue';

import PoweredByOkendo from '@/shared-components/PoweredByOkendo.vue';
import ProductImage from '@/shared-components/ProductImage.vue';
import ViewState, { ViewStateStatus } from '@/shared-components/ViewState.vue';
import AchievementHeader from '@/shared-components/AchievementHeader.vue';

import store from '@/store';
import { verifyProductId } from '@/utils/productUtils';
import { postAnalytics, postErrorAnalytics } from '@/utils/api';
import { isAuthenticated, isLoyaltyModule } from '@/utils/loyaltyUtils';

import Referral from './components/Referral.vue';
import ReviewOtherProducts from './components/ReviewOtherProducts.vue';
import SiteReview from './components/SiteReview.vue';
import ShopMoreProducts from './components/ShopMoreProducts.vue';
import Survey from './components/Survey.vue';
import CustomButton from './components/CustomButton.vue';
import CustomText from './components/CustomText.vue';
import CustomImage from './components/CustomImage.vue';
import { StoreMethod } from '@/store/storeTypings';
import eventBus from '@/utils/mittUtil';

@Options({
    components: {
        AchievementHeader,
        CustomButton,
        CustomImage,
        CustomText,
        Referral,
        ReviewOtherProducts,
        SiteReview,
        Survey,
        ShopMoreProducts,
        PoweredByOkendo,
        ProductImage,
        ViewState
    },
    store
})
export default class PostReview extends Vue {
    viewState: ViewStateStatus = 'loading';
    isPreviouslyReviewed = false;
    loyaltySetupCompleted = false;
    loyaltyProfileComponent: Component | undefined = undefined;

    mounted(): void {
        window.scrollTo(0, 0);
        postAnalytics({ eventName: 'page-open' });
        this.loadPostReviewData();
        this.setupLoyalty();

        if (this.isPreviewMode) {
            eventBus.on('adminIframeUpdate', () => {
                this.setupLoyalty();
            });
        }
    }

    get hasLoyaltyModules(): boolean {
        const { postReviewModules } = store.state.settings.settings;
        return !!postReviewModules?.some((module: PostReviewModules) => isLoyaltyModule(module.type));
    }

    async setupLoyaltyModules(): Promise<void> {
        if ((!this.isPreviewMode && !this.hasLoyaltyModules) || this.loyaltySetupCompleted) {
            return;
        }

        if (!this.loyaltyProfileComponent) {
            this.loyaltyProfileComponent = defineAsyncComponent(
                () => import(/* webpackChunkName: "loyalty" */'./components/LoyaltyProfile.vue')
            );
        }

        if (!store.state.loyalty) {
            await import(/* webpackChunkName: "loyalty" */ '../../store/modules/loyalty').then(
                ({ LoyaltyModule }) => store.registerModule('loyalty', LoyaltyModule)
            );
        }

        if (!store.state.loyaltyAuth) {
            await import(/* webpackChunkName: "loyalty" */ '../../store/modules/loyalty-auth').then(
                ({ LoyaltyAuthModule }) => store.registerModule('loyaltyAuth', LoyaltyAuthModule)
            );
        }

        this.loyaltySetupCompleted = true;
    }

    async setupLoyalty(): Promise<void> {
        const errorLoading = (error?: Error): void => {
            store.dispatch<StoreMethod>({
                type: 'alert/SHOW',
                alertData: {
                    content: this.$t(error?.message || 'An error occurred while initializing the Loyalty Widget, please try again'),
                    status: 'fail'
                }
            });
        };

        try {
            await this.setupLoyaltyModules();

            if (this.loyaltySetupCompleted) {
                await store.dispatch<StoreMethod>({
                    type: 'loyalty/INITIALISE_LOYALTY'
                });
                await store.dispatch<StoreMethod>({
                    type: 'loyalty/GET_MEMBER_DATA'
                });
            }
        }
        catch (error) {
            errorLoading(error as Error);
        }
    }

    async loadPostReviewData(): Promise<void> {
        this.viewState = 'loading';
        try {
            await verifyProductId(this.$route.query.productId);
            this.isPreviouslyReviewed = store.state.product.isPreviouslyReviewed;
            this.viewState = 'content';
        }
        catch (error) {
            postErrorAnalytics(error);
            this.viewState = 'error';
        }
    }

    get product(): ProductRecorderView {
        return store.state.product.product;
    }

    get moduleComponents(): PostReviewModules[] {
        const { postReviewModules } = store.state.settings.settings;
        let modules: PostReviewModules[] | undefined;

        if (postReviewModules) {
            modules = this.setupPostReviewModules([...postReviewModules]);
        }

        return modules || [];
    }

    get isAnonymousReview(): boolean {
        return !store.state.order.reviewRequestId && !store.state.profile.isLoggedIn && !store.state.verification.emailVerificationToken;
    }

    get showGotoStoreButton(): boolean {
        if (!this.storeUrl) {
            return false;
        }

        const activeComponents = this.moduleComponents.filter((module: PostReviewModules) => {
            const { type } = module;
            if (type === 'review-other-products') {
                return !!store.state.order.remainingProductsToReview?.length;
            }
            else if (type === 'site-review') {
                const { hasSiteReview, submittedSuccessfully } = store.state.siteReview;
                const { name } = store.state.profile.reviewerProfile;
                const { reviewRequestId } = store.state.order;
                return !!name && !!reviewRequestId && !hasSiteReview && !submittedSuccessfully;
            }
            else if (type === 'shop-more-products') {
                const { isLoaded, products } = store.state.shopMoreProducts;
                return !isLoaded || !!products?.length;
            }
            else if (type === 'survey') {
                const { channelSurveyId, isLoadingSurvey } = store.state.survey;
                return isLoadingSurvey || !!channelSurveyId;
            }
            else if (type === 'referral') {
                const isPreviewMode = store.state.subscriber.previewMode;
                if (isPreviewMode) {
                    return true;
                }

                const rating = store.state.review.review?.rating;
                return rating && rating >= module.minimumRating;
            }
            else {
                return true;
            }
        });

        return !activeComponents.length && !this.hasLoyaltyModules;
    }

    get storeName(): string | undefined {
        const { storeName } = store.state.subscriber;
        return (storeName && storeName.length < 23) ? storeName : this.$t('Store');
    }

    get storeUrl(): string {
        return store.state.subscriber.storeUrl;
    }

    get isLoyaltyAuthenticated(): boolean {
        return this.isPreviewMode ? true : isAuthenticated();
    }

    get isPreviewMode(): boolean {
        return store.state.subscriber.previewMode;
    }

    gotoStore(): void {
        window.location.href = this.storeUrl;
    }

    setupPostReviewModules(postReviewModules: PostReviewModules[]): PostReviewModules[] {
        let _postReviewModules: PostReviewModules[] = [];

        // If the user is not logged into loyalty, remove all other loyalty modules except for the profile module
        if (!this.isLoyaltyAuthenticated) {
            _postReviewModules = postReviewModules.filter((module: PostReviewModules) => {
                return !isLoyaltyModule(module.type);
            });
        }
        else {
            postReviewModules.forEach(module => {
                switch (module.type) {
                    case 'loyalty-coupons':
                        _postReviewModules.push({
                            ...module,
                            type: 'loyalty-coupons',
                            component: defineAsyncComponent(
                                () => import(/* webpackChunkName: "loyalty" */'./components/LoyaltyCoupons.vue')
                            )
                        });
                        break;
                    case 'loyalty-vip-tiers':
                        _postReviewModules.push({
                            ...module,
                            type: 'loyalty-vip-tiers',
                            component: defineAsyncComponent(
                                () => import(/* webpackChunkName: "loyalty" */'./components/LoyaltyVipTiers.vue')
                            )
                        });
                        break;
                    case 'loyalty-earn':
                        _postReviewModules.push({
                            ...module,
                            type: 'loyalty-earn',
                            component: defineAsyncComponent(
                                () => import(/* webpackChunkName: "loyalty" */'./components/LoyaltyEarn.vue')
                            )
                        });
                        break;
                    case 'loyalty-redeem':
                        _postReviewModules.push({
                            ...module,
                            type: 'loyalty-redeem',
                            component: defineAsyncComponent(
                                () => import(/* webpackChunkName: "loyalty" */'./components/LoyaltyRedeem.vue')
                            )
                        });
                        break;
                    default:
                        _postReviewModules.push(module);
                        break;
                }
            });
        }

        return _postReviewModules;
    }
}

export type PostReviewModules = { component?: Component; } & StyleSettings.RecorderPlus.ReviewModule;
