
import { ProductLike } from '@okendo/reviews-common';
import { Options, Vue } from 'vue-class-component';

import ProductToReview from '@/shared-components/ProductToReview.vue';
import store from '@/store';

@Options({
    components: {
        ProductToReview
    },
    store
})
export default class ReviewOtherProducts extends Vue {
    get remainingProductsToReview(): ProductLike[] {
        return store.state.order.remainingProductsToReview || [];
    }
}
