
import { StyleSettings } from '@okendo/reviews-common';
import { Options, Vue } from 'vue-class-component';

import store from '@/store';
import ViewState from '@/shared-components/ViewState.vue';
import { loadSurveyScript } from '@/utils/surveyUtil';

@Options({
    components: {
        ViewState
    },
    store
})
export default class Survey extends Vue {
    mounted(): void {
        if (this.isModuleVisible) {
            this.loadScript();
        }
    }

    loadScript(): void {
        if (this.channelSurveyId) {
            loadSurveyScript();
        }
        else {
            const watchStop = this.$watch('channelSurveyId', (id: string) => {
                if (id) {
                    loadSurveyScript();
                    watchStop();
                }
            });
        }
    }

    get isModuleVisible(): boolean {
        return this.isLoadingSurvey || !!this.channelSurveyId;
    }

    get channelSurveyId(): string {
        return store.state.survey.channelSurveyId || '';
    }

    get isLoadingSurvey(): boolean {
        return store.state.survey.isLoadingSurvey;
    }

    get headingText(): string {
        return this.surveyModule?.headingText ?? 'Answer a Few Extra Questions?';
    }

    get subheadingText(): string | undefined {
        return this.surveyModule?.subheadingText;
    }

    get surveyModule(): StyleSettings.RecorderPlus.Module.Survey | undefined {
        const { postReviewModules } = store.state.settings.settings;
        const surveyModule = postReviewModules?.find(module => module.type === 'survey');
        return surveyModule as StyleSettings.RecorderPlus.Module.Survey | undefined;
    }
}
